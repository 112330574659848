// Modules
import React from "react";
import { graphql } from "gatsby";

// Components
import Hero from "../components/hero";
import Layout from "../components/layout";
import Loader from "../components/loader";
import PageBuilder from "../components/pageBuilder";
import Statistics from "../components/statistics";
import Seo from "../components/seo/seo";

// Helpers
import { getVideoDataInPage } from "../helpers/getVideoDataInPage";

import * as styles from "./styles.module.scss";
import * as blogStyles from "../templates/blogsStyles.module.scss";
import RICH_TEXT_TYPES from "../constants/richTextTypes";

// Constants

const IndexPage = ({ data }) => {
  const content = data?.sanityHomepage?.tabs?._rawContent;
  const seo = data?.sanityHomepage?.tabs?._rawSeo;
  const heroTitle = content.hero.heroText.titleText;
  const classNamesObj = {
    [RICH_TEXT_TYPES.pbRichText]: styles.homePbRichTextWrapper,
    [RICH_TEXT_TYPES.testimonial]: styles.homeTestimonialWrapper,
  };
  const video = getVideoDataInPage(content?.pageBuilder);

  return (
    <Layout stickyHeader>
      <Seo
        title={heroTitle}
        image={content.hero.bgImage}
        video={video}
        {...seo}
      />
      {content ? (
        <>
          <Hero
            className={styles.homePageHero}
            contentClassName={styles.homePageHeroContent}
            imageClassName={blogStyles.heroImg}
            image={content.hero.bgImage}
            subTitle={content.hero.heroText.subtitleText}
            title={content.hero.heroText.titleText}
          />
          <Statistics statisticItems={content.statistics.statisticItems} />
          <PageBuilder
            pageBuilderContents={content.pageBuilder}
            classNamesObj={classNamesObj}
          />
        </>
      ) : (
        <Loader />
      )}
    </Layout>
  );
};

export default IndexPage;

export const query = graphql`
  query {
    sanityHomepage {
      tabs {
        _rawContent(resolveReferences: { maxDepth: 20 })
        _rawSeo(resolveReferences: { maxDepth: 10 })
      }
    }
  }
`;
