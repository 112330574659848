// Modules
import React from 'react';
import PropTypes from "prop-types";

// Styles
import * as styles from './statistics.module.scss';

const StatisticsItem = ({ index, length, statisticSubtitle, statisticTitle }) => {
  const notLastItem = (index + 1) < length;

  return (
    <>
      <div className={styles.statisticItem}>
        <div className={styles.h3}>{statisticTitle}</div>
        <div className={styles.h5}>{statisticSubtitle}</div>
      </div>
      {notLastItem && <div className={styles.divider} />}
    </>
  );
}

StatisticsItem.propTypes = {
  index: PropTypes.number,
  length: PropTypes.number,
  statisticSubtitle: PropTypes.string.isRequired,
  statisticTitle: PropTypes.string.isRequired,
};

StatisticsItem.defaultProps = {
  index: 0,
  length: 1,
}

export default StatisticsItem;
