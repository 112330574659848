// Modules
import React from 'react';
import PropTypes from "prop-types";

// Components
import StatisticsItem from './statisticsItem';

// Constants
import COLORS from '../../constants/colors';

// Styles
import * as styles from './statistics.module.scss';

const Statistics = ({ bgColor, statisticItems }) => {
  const style = { backgroundColor : bgColor };

  return (
    <div className={styles.statisticsWrapper}>
      <div className={styles.content} style={style}>
        {statisticItems.map(({ _key, statisticSubtitle, statisticTitle }, i) => (
          <StatisticsItem
            index={i}
            key={_key}
            length={statisticItems.length}
            statisticSubtitle={statisticSubtitle}
            statisticTitle={statisticTitle}
          />
        ))}
      </div>
    </div>
  );
}

Statistics.propTypes = {
  bgColor: PropTypes.string,
  statisticItems: PropTypes.array,
};

Statistics.defaultProps = {
  bgColor: COLORS.darkGrey,
  statisticItems: [],
};

export default Statistics;
